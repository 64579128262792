// const baseURL = 'https://localhost:44389/api/`;
const baseURL = 'https://sahajmarriageapi.sahajayogaindia.org.in/api/';

export const LOGIN_URL = `${baseURL}Account/applicantlogin`;
export const COUNTRY_URL = `${baseURL}Masters/getcountries`;
export const GET_STATE_BY_COUNTRY_ID = `${baseURL}Masters/getstatesbycountryid`;
export const GET_DISTRICT_BY_STATE_ID = `${baseURL}Masters/getdistrictsbystateid`;
export const GET_CITY_BY_DISTRICT_ID = `${baseURL}Masters/getcitiesbydistrictid`;
export const GET_EVENTS = `${baseURL}Masters/getevents`;

export const ADD_REGISTRATION = `${baseURL}Applicant/addregistration`;
export const GET_MARRIAGE_APPLICATION_BY_ID = `${baseURL}Applicant/getmarriageapplicationbyid`;
export const ADD_UPDATE_MARRIAGE_APPLICATION = `${baseURL}Applicant/addudatemarriageapplication`;

export default LOGIN_URL;