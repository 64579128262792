
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment';
import Select from 'react-select';
import * as service from '../../services/service';

function Registration() {
    const [state, setState] = useState(
        {
            firstname: '', middlename: '', lastname: '', center: '', emailid: '', mobileno: '', pan: '', adharcardno: '',
            realizationmonthyear: '', flatno: '', buildingname: '', streetname: '', locality: '', village: '', pincode: '',
            password: '', attendingsince: '', citycoordinatorname: '', citycoordinatorcontact: '', citycoordinatoremailId: '',
        });
    const [countryId, setCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [cityId, setCityId] = useState('');
    const [cityData, setCityData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [gender, setGender] = useState('');
    const [birthDate, setBirthDate] = useState(new Date());
    const [panFile, setPanFile] = useState('');
    const [adharFile, setAdharFile] = useState('');
    const genderData = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();

    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);
            });
    };

    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };
    const getCities = (id) => {
        fetch(
            service.GET_CITY_BY_DISTRICT_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    DistrictId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.cityId, label: item.city }));
                setCityData(data2);
            });
    };
    useEffect(() => {
        getCountries();
        const def = { value: 1, label: 'India' };
        setCountryId(def);
        getStates(def.value);

        const gen = { label: 'Male', value: 'Male' };
        setGender(gen);
    }, []);

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.firstname) {
            isValid = false;
            errs[".firstname"] = "Please enter first name";
        }
        if (!state.middlename) {
            isValid = false;
            errs[".middlename"] = "Please enter middle name";
        }
        if (!state.lastname) {
            isValid = false;
            errs[".lastname"] = "Please enter last name";
        }
        if (!birthDate) {
            isValid = false;
            errs[".birthDate"] = "Please enter Birth Date";
        }
        if (!state.flatno) {
            isValid = false;
            errs[".pan"] = "Please enter flat no.";
        }
        if (!stateId) {
            isValid = false;
            errs[".state"] = "Please select state";
        }
        if (!districtId) {
            isValid = false;
            errs[".district"] = "Please select district";
        }
        if (!cityId) {
            isValid = false;
            errs[".city"] = "Please select city";
        }
        if (!state.emailid) {
            isValid = false;
            errs[".emailId"] = "Please enter emailId";
        }
        if (!state.mobileno) {
            isValid = false;
            errs[".mobileno"] = "Please enter mobile no.";
        }
        if (!state.pan) {
            isValid = false;
            errs[".pan"] = "Please enter PAN";
        }
        if (!state.flatno) {
            isValid = false;
            errs[".flatno"] = "Please enter Flat No.";
        }
        if (!state.buildingname) {
            isValid = false;
            errs[".buildingname"] = "Please enter building name";
        }
        if (!state.streetname) {
            isValid = false;
            errs[".streetname"] = "Please enter street name";
        }
        if (!state.pincode) {
            isValid = false;
            errs[".pincode"] = "Please enter pin code";
        }
        if (!state.adharcardno) {
            isValid = false;
            errs[".adharcardno"] = "Please enter Adhar Card No.";
        }
        if (!state.realizationmonthyear) {
            isValid = false;
            errs[".realizationmonthyear"] = "Please enter realization month-year";
        }
        if (!state.center) {
            isValid = false;
            errs[".center"] = "Please select center";
        }
        if (!state.attendingsince) {
            isValid = false;
            errs[".attendingsince"] = "Please enter attending since";
        }
        if (!state.citycoordinatorname) {
            isValid = false;
            errs[".citycoordinatorname"] = "Please enter citycoordinator name";
        }
        if (!state.citycoordinatorcontact) {
            isValid = false;
            errs[".citycoordinatorcontact"] = "Please enter citycoordinator contact";
        }
        if (!state.citycoordinatoremailId) {
            isValid = false;
            errs[".citycoordinatoremailId"] = "Please enter citycoordinator emailId";
        }
        if (!panFile) {
            isValid = false;
            errs[".panFile"] = "Please upload PAN";
        }
        if (!adharFile) {
            isValid = false;
            errs[".adharFile"] = "Please upload Adhar Card";
        }
        if (panFile && getExtension(panFile.name).toLowerCase() !== 'pdf') {
            isValid = false;
            errs[".panFile"] = "Please upload pdf file";
        }
        if (adharFile && getExtension(adharFile.name).toLowerCase() !== 'pdf') {
            isValid = false;
            errs[".adharFile"] = "Please upload pdf file";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };

    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateId(null);
        setDistrictId(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
        setCityId(null)
        getCities(ev.value);
    }
    const cityChangeHandler = (ev) => {
        setCityId(ev);
    }
    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("FirstName", state.firstname);
            formData.append("MiddleName", state.middlename);
            formData.append("LastName", state.lastname);
            formData.append("MobileNo", state.mobileno);
            formData.append("EmailId", state.emailid);
            formData.append("PAN", state.pan);
            formData.append("AdharCardNo", state.adharcardno);
            formData.append("BirthDate", moment(birthDate).format("MM/DD/YYYY"));
            formData.append("Gender", gender.value);
            formData.append("RealizationMonthYear", state.realizationmonthyear);
            formData.append("FlatNo", state.flatno);
            formData.append("BuildingName", state.buildingname);
            formData.append("StreetName", state.streetname);
            formData.append("Locality", state.locality);
            formData.append("Village", state.village);
            formData.append("PinCode", state.pincode);
            formData.append("CountryId", countryId.value);
            formData.append("StateId", stateId ? stateId.value : 0);
            formData.append("DistrictId", districtId ? districtId.value : 0);
            formData.append("CityId", cityId ? cityId.value : 0);
            formData.append("Password", state.password ? state.password : `${state.firstname}@123`);
            formData.append("Center", state.center);
            formData.append("AttendingSince", state.attendingsince);
            formData.append("CityCoordinatorName", state.citycoordinatorname);
            formData.append("CityCoordinatorContact", state.citycoordinatorcontact);
            formData.append("CityCoordinatorEmailId", state.citycoordinatoremailId);
            formData.append("IsVerified", 0);
            formData.append("PANFile", panFile);
            formData.append("AdharFile", adharFile);
            fetch(
                service.ADD_REGISTRATION,
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        navigate('/registrationsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <div style={{ width: '100%' }}>
            {/* <DashboardNavbar /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Registration
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', height: '800px' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="First Name" fullWidth value={state.firstname || ''}
                                                        name="firstname"
                                                        onChange={changeHandler}
                                                        id="firstname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Middle Name" fullWidth value={state.middlename || ''}
                                                        name="middlename"
                                                        onChange={changeHandler}
                                                        id="middlename"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Last Name" fullWidth value={state.lastname || ''}
                                                        name="lastname"
                                                        onChange={changeHandler}
                                                        id="lastname"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="House/Plot/Flat No." fullWidth value={state.flatno || ''}
                                                        name="flatno"
                                                        onChange={changeHandler}
                                                        id="flatno"
                                                        required />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".flatno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Building Name" fullWidth value={state.buildingname || ''}
                                                        name="buildingname"
                                                        onChange={changeHandler}
                                                        id="buildingname"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".buildingname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Street/Road No./Name" fullWidth value={state.streetname || ''}
                                                        name="streetname"
                                                        onChange={changeHandler}
                                                        id="streetname"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".streetname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Locality/Mohalla" fullWidth value={state.locality || ''}
                                                        name="locality"
                                                        onChange={changeHandler}
                                                        id="locality"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".locality"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Village" fullWidth value={state.village || ''}
                                                        name="village"
                                                        onChange={changeHandler}
                                                        id="village"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".village"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Pin Code" fullWidth value={state.pincode || ''}
                                                        name="pincode"
                                                        onChange={changeHandler}
                                                        id="pincode"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pincode"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="country"
                                                        name="country"
                                                        options={countryData}
                                                        value={countryId}
                                                        onChange={countryChangeHandler}
                                                        label="Country"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        options={stateData}
                                                        name="state"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                        placeholder="Select State"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={cityData}
                                                        name="city"
                                                        value={cityId}
                                                        onChange={cityChangeHandler}
                                                        isSearchable
                                                        placeholder="Select City"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".city"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Mobile No" fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Email" fullWidth value={state.emailid || ''}
                                                        name="emailid"
                                                        type="email"
                                                        onChange={changeHandler}
                                                        id="emailid"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailId"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="gender"
                                                        name="gender"
                                                        options={genderData}
                                                        value={gender}
                                                        onChange={ev => setGender(ev)}
                                                        placeholder="Select Gender"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Center" fullWidth value={state.center || ''}
                                                        name="center"
                                                        onChange={changeHandler}
                                                        id="center"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="City Coordinator Name" fullWidth value={state.citycoordinatorname || ''}
                                                        name="citycoordinatorname"
                                                        onChange={changeHandler}
                                                        id="citycoordinatorname"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".citycoordinatorname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="City Coordinator Contact" fullWidth value={state.citycoordinatorcontact || ''}
                                                        name="citycoordinatorcontact"
                                                        onChange={changeHandler}
                                                        id="citycoordinatorcontact"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".citycoordinatorcontact"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="City Coordinator EmailId" fullWidth value={state.citycoordinatoremailId || ''}
                                                        name="citycoordinatoremailId"
                                                        onChange={changeHandler}
                                                        id="citycoordinatoremailId"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".citycoordinatoremailId"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Attending Since" fullWidth value={state.attendingsince || ''}
                                                        name="attendingsince"
                                                        onChange={changeHandler}
                                                        id="attendingsince"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".attendingsince"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Birth Date
                                                    <DatePicker
                                                        id="birthDate"
                                                        selected={birthDate}
                                                        onChange={date => setBirthDate(date)}
                                                        dateFormat="MM/dd/yyyy"
                                                        tabIndex={0}
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        dropdownMode="scroll"
                                                        peekNextMonth
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".birthDate"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Realization Month-Year" fullWidth value={state.realizationmonthyear || ''}
                                                        name="realizationmonthyear"
                                                        onChange={changeHandler}
                                                        id="realizationmonthyear"
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".realizationmonthyear"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="PAN" fullWidth value={state.pan || ''}
                                                        name="pan"
                                                        onChange={changeHandler}
                                                        id="pan"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Adhar Card No" fullWidth value={state.adharcardno || ''}
                                                        name="adharcardno"
                                                        onChange={changeHandler}
                                                        id="adharcardno"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharcardno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    PAN Upload
                                                    <br />
                                                    <TextField
                                                        id="panFile"
                                                        type="file"
                                                        onChange={ev => setPanFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".panFile"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    Adhar Card Upload
                                                    <br />
                                                    <TextField
                                                        id="adharFile"
                                                        type="file"
                                                        onChange={ev => setAdharFile(ev.target.files[0])}
                                                        style={{ width: 300 }}
                                                        required
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharFile"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <Table style={{ width: '20%' }}>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>
                                                }
                                                {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <MDButton color="secondary" onClick={backHandler}>
                                                    Back
                                                </MDButton>
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </div>
    );
}

export default Registration;