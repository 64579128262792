/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
import MDButton from "components/MDButton";
// @mui icons
// import Icon from "@mui/material/Icon";
// Authentication layout components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import BasicLayout from "layouts/authentication/components/BasicLayout";
// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import * as service from '../../services/service';

function MarrigeFormSuccess() {
    const [data, setData] = useState([]);
    // const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [profilePic, setProfilePic] = useState('');
    const navigate = useNavigate();
    const params = useParams();

    const getDetailsById = () => {
        setLoading(true);
        // const errs = {};
        fetch(
            service.GET_MARRIAGE_APPLICATION_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    RegistrationId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
                setProfilePic(`https://localhost:44379/api/wwwroot/UploadedFiles/${result.registrationId}/${result.photoFileName}`);

            })
            .catch(err => {
                if (err) {
                    // eslint-disable-next-line
                    alert(err);
                    // setError(err);
                };
            });
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getDetailsById();
        setLoading(false);
    }, []);

    const printDocument = () => {
        const input = document.getElementById('tblForm');
        html2canvas(input,  { scale: "5" })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/jpeg', 0.3);
                const imgWidth = 190;
                const pageHeight = 290;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
                let heightLeft = imgHeight;
                // eslint-disable-next-line new-cap
                const doc = new jsPDF('pt', 'mm', 'a4', true);
                let position = 0;
                doc.addImage(imgData, 'JPEG', 5, 0, imgWidth, imgHeight + 30, undefined, 'FAST');
                heightLeft -= pageHeight;
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'JPEG', 5, position, imgWidth, imgHeight + 30, undefined, 'FAST');
                    heightLeft -= pageHeight;
                }
            doc.save('MarriageApplication.pdf');		
        });

        // html2canvas(input, { scale: "5" })
        //     .then((canvas) => {
        //         // const imgData = canvas.toDataURL('image/png');
        //         const imgData = canvas.toDataURL('image/jpeg', 0.3);
        //         const imgWidth = 190;
        //         const imgHeight = (canvas.height * imgWidth) / canvas.width;
        //         // eslint-disable-next-line new-cap
        //         const doc = new jsPDF('pt', 'mm', 'a4', true);
        //         // doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
        //         doc.addImage(imgData, "JPEG", 5, 0, imgWidth, imgHeight + 25, undefined, 'FAST');
        //         doc.save('MarriageApplication.pdf');
        //     });
    }
    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    return (
        <div style={{ width: '100%' }}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                        Marriage Application Successful
                    </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <div align='center' style={{ fontSize: '16px', fontWeight: 'bold', alignItems: 'center' }}>
                            Thank you for marriage application. Please download the application.<br /> Jai Shri Mataji.
                        </div>
                    </MDBox>
                    <Card style={{ alignItems: 'center' }}>
                        <MDBox pt={4} pb={3} px={3} style={{ width: '80%' }}>
                            <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                {loading ? <Oval
                                    heigth="20"
                                    width="20"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <Table id="tblForm" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell colSpan={2} align="center" style={{ fontWeight: 'bold', fontSize: '16px', color: 'blue' }}>
                                                    H.H. SHRI MATAJI NIRMALA DEVI SAHAJAYOGA TRUST - BIRTHDAY PUJA 2023 MAHOSTAV
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Application No :
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.applicationNo}
                                                                </TableCell>
                                                                <TableCell colSpan={4} align='right'>
                                                                    <img src={profilePic} alt="profile" />
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell width='15%' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    First Name
                                                                </TableCell>
                                                                <TableCell width='15%'>
                                                                    {data.firstName}
                                                                </TableCell>
                                                                <TableCell width='15%' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Middle Name
                                                                </TableCell>
                                                                <TableCell width='15%'>
                                                                    {data.middleName}
                                                                </TableCell>
                                                                <TableCell width='15%' style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Last Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.lastName}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    House/Plot/Flat No.
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.flatNo}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Building Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.buildingName}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Street/Road No./Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.streetName}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Locality/Mohalla
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.locality}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Village
                                                                </TableCell>
                                                                <TableCell >
                                                                    {data.village}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    PinCode
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.pinCode}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Country
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.country}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    State
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.state}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    District
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.district}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    City
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.city}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Mobile No.
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.mobileNo}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Email Id
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.emailId}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Gender
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.gender}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Birth Date (DD/MM/YYYY)
                                                                </TableCell>
                                                                <TableCell>
                                                                    {moment(data.birthDate).format("DD/MM/YYYY")}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Age
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.age}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Center
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.center}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Attending Since
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.attendingSince}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    City Coordinator Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.cityCoordinatorName}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    City Coordinator Contact
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.cityCoordinatorContact}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    City Coordinator Email Id
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.cityCoordinatorEmailId}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Realization Month-Year
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.realizationMonthYear}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    PAN
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.pan}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Adhar Card No.
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.adharCardNo}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    PAN File
                                                                    <div style={{ width: 300, fontSize: 14 }}>{data.panFileName}</div>
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Adhar Card File
                                                                    <div style={{ width: 300, fontSize: 14 }}>{data.adharFileName}</div>
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Education Details
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Table>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>S.No.</TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Examination Passed</TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Degree/Certificate</TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Year Of Passing</TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>School/College/University</TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Distinguished Performance/Achievements</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>1</TableCell>
                                                                        <TableCell>
                                                                            {data.exam1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.degree1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.passingYear1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.university1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.achievement1}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>2</TableCell>
                                                                        <TableCell>
                                                                            {data.exam2}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.degree2}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.passingYear2}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.university2}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.achievement2}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>3</TableCell>
                                                                        <TableCell>
                                                                            {data.exam3}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.degree3}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.passingYear3}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.university3}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.achievement3}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>4</TableCell>
                                                                        <TableCell>
                                                                            {data.exam4}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.degree4}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.passingYear4}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.university4}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.achievement4}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>5</TableCell>
                                                                        <TableCell>
                                                                            {data.exam5}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.degree5}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.passingYear5}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.university5}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.achievement5}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    </Table>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Professional Details
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Occupation
                                                            </TableCell>
                                                            <TableCell >
                                                                {data.occupation}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Annual Income
                                                            </TableCell>
                                                            <TableCell >
                                                                {data.annualIncome}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Present Assignment
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Designation
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.designation}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Organization Name
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.organizationName}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Nature Of Work
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.natureOfWork}
                                                            </TableCell>
                                                        </TableRow>
                                                    </Table>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Physical
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Height
                                                            </TableCell>
                                                            <TableCell >
                                                                {data.height}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Weight
                                                            </TableCell>
                                                            <TableCell >
                                                                {data.weight}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Complextion
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.complextion}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Handicap
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.handicap}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Previous Health Problems
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.previousHealthProblem}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Present Health Problems
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.presentHealthProblem}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Marrital Status
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.marritalStatus}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Son(s)
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.son}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Daughter(s)
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.daughter}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Hobbies
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.hobbies}
                                                            </TableCell>
                                                        </TableRow>
                                                    </Table>
                                                    <Table>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Father’s /Guardian’s Details:
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Name
                                                                </TableCell>
                                                                <TableCell >
                                                                    {data.fatherName}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Relation
                                                                </TableCell>
                                                                <TableCell >
                                                                    {data.relation}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    House/Plot/Flat No.
                                                                </TableCell>
                                                                <TableCell >
                                                                    {data.fatherFlatNo}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Building Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherBuildingName}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Street/Road No./Name
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherStreetName}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Locality/Mohalla
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherLocality}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Village
                                                                </TableCell>
                                                                <TableCell >
                                                                    {data.fatherVillage}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }} >
                                                                    PinCode
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherPinCode}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Country
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherCountry}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    State
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherState}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    District
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherDistrict}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    City
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherCity}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Mobile No.
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherMobileNo}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Email Id
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherEmailId}
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Occupation
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherOccupation}
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                    Annual Income
                                                                </TableCell>
                                                                <TableCell>
                                                                    {data.fatherAnnualIncome}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                    <Table>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Family Details
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell colSpan={6}>
                                                                <Table>
                                                                    <TableRow>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>S.No.</TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Name</TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Relation</TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>SY Joined(Year, Center)</TableCell>
                                                                        <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>Married In Sahajayoga (Yes/No)</TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>1</TableCell>
                                                                        <TableCell>
                                                                            {data.familyMemberName1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.familyMemberRelation1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.syJoinedYear1}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.sahajMarriage1}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>2</TableCell>
                                                                        <TableCell>
                                                                            {data.familyMemberName2}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.familyMemberRelation2}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.syJoinedYear2}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.sahajMarriage2}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>3</TableCell>
                                                                        <TableCell>
                                                                            {data.familyMemberName3}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.familyMemberRelation3}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.syJoinedYear3}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.sahajMarriage3}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>4</TableCell>
                                                                        <TableCell>
                                                                            {data.familyMemberName4}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.familyMemberRelation4}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.syJoinedYear4}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.sahajMarriage4}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow>
                                                                        <TableCell>5</TableCell>
                                                                        <TableCell>
                                                                            {data.familyMemberName5}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.familyMemberRelation5}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.syJoinedYear5}
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {data.sahajMarriage5}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Staying With Joined Family
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.jointFamily}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                (if yes, indicate the relations staying together)
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.relationsStayingTogether}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Are you willing to change Country
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.relocateCountry}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Are you willing to change State
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.relocateState}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Did you apply to Sahaja Marriage earlier
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.sahajMarriageEarlier}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                (if yes, which year)
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.earlierYear}
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Selected/Not Selected
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.earlierSelection}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                If yes, why marriage did not take place
                                                            </TableCell>
                                                            <TableCell>
                                                                {data.reasonOfNotMarried}
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Degree File:
                                                                <div style={{ width: 300, fontSize: 14 }}>{data.degreeFileName}</div>
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Job File:
                                                                <div style={{ width: 300, fontSize: 14 }}>{data.jobFileName}</div>
                                                            </TableCell>
                                                            <TableCell style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                                Divorce File:
                                                                <div style={{ width: 300, fontSize: 14 }}>{data.divorceFileName}</div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                }
                            </TableContainer>
                        </MDBox>
                        <MDBox mt={4} mb={1} textAlign="center">
                            <Table>
                                <TableRow>
                                    <TableCell>
                                        <MDButton color="info" onClick={() => printDocument()}>
                                            Download
                                        </MDButton>
                                    </TableCell>
                                    <TableCell>
                                        <MDButton color="secondary" onClick={backHandler}>
                                            Back
                                        </MDButton>
                                    </TableCell>
                                </TableRow>
                            </Table>
                        </MDBox>
                    </Card>
                </MDBox>
            </Card>
        </div>
    );
}

export default MarrigeFormSuccess;
