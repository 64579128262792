
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDButton from 'components/MDButton';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import MDInput from "components/MDInput";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner';
import { TextField } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import * as moment from 'moment';
import Select from 'react-select';
import * as service from '../../services/service';

function MarriageForm() {
    const [state, setState] = useState(
        {
            firstname: '', middlename: '', lastname: '', center: '', emailid: '', mobileno: '', pan: '', adharcardno: '',
            realizationmonthyear: '', flatno: '', buildingname: '', streetname: '', locality: '', village: '', pincode: '',
            password: '', attendingsince: '', citycoordinatorname: '', citycoordinatorcontact: '', citycoordinatoremailId: '',
            registrationid: '', applicationid: 0, applicationno: '', fathername: '', relation: '', fatheroccupation: '', fatherannualincome: '',
            fatheremailid: '', fathermobileno: '', fatherflatno: '', fatherbuildingname: '', fatherstreetname: '', fatherlocality: '',
            fathervillage: '', fatherpincode: '',
            exam1: '', degree1: '', passingyear1: '', university1: '', achievement1: '',
            exam2: '', degree2: '', passingyear2: '', university2: '', achievement2: '',
            exam3: '', degree3: '', passingyear3: '', university3: '', achievement3: '',
            exam4: '', degree4: '', passingyear4: '', university4: '', achievement4: '',
            exam5: '', degree5: '', passingyear5: '', university5: '', achievement5: '',
            annualincome: '', designation: '', organizationname: '', natureofwork: '', height: '', weight: '',
            handicap: '', hobbies: '', previoushealthproblem: '', presenthealthproblem: '',
            son: '', daughter: '', familymembername1: '', familymemberrelation1: '', syjoinedyear1: '', sycenter1: '', sahajmarriage1: '',
            familymembername2: '', familymemberrelation2: '', syjoinedyear2: '', sycenter2: '', sahajmarriage2: '',
            familymembername3: '', familymemberrelation3: '', syjoinedyear3: '', sycenter3: '', sahajmarriage3: '',
            familymembername4: '', familymemberrelation4: '', syjoinedyear4: '', sycenter4: '', sahajmarriage4: '',
            familymembername5: '', familymemberrelation5: '', syjoinedyear5: '', sycenter5: '', sahajmarriage5: '',
            relationsstayingtogether: '', earlieryear: '',
            reasonofnotmarried: '', citycoordinatorfeedback: '', districtcoordinatorfeedback: '',
            statecoordinatorfeedback: '', panfilename: '', adharfilename: '', photofilename: '', degreefilename: '', jobfilename: '',
            divorcefilename: '', sentforapproval: ''
        });
    const [countryId, setCountryId] = useState('');
    const [fatherCountryId, setFatherCountryId] = useState('');
    const [countryData, setCountryData] = useState([]);
    const [fatherCountryData, setFatherCountryData] = useState([]);
    const [stateId, setStateId] = useState('');
    const [fatherStateId, setFatherStateId] = useState('');
    const [stateData, setStateData] = useState([]);
    const [fatherStateData, setFatherStateData] = useState([]);
    const [districtId, setDistrictId] = useState('');
    const [fatherDistrictId, setFatherDistrictId] = useState('');
    const [districtData, setDistrictData] = useState([]);
    const [fatherDistrictData, setFatherDistrictData] = useState([]);
    const [cityId, setCityId] = useState('');
    const [fatherCityId, setFatherCityId] = useState('');
    const [cityData, setCityData] = useState([]);
    const [fatherCityData, setFatherCityData] = useState([]);
    const [eventId, setEventId] = useState('');
    const [eventData, setEventData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [gender, setGender] = useState('');
    const [birthDate, setBirthDate] = useState(new Date());
    const [photoFile, setPhotoFile] = useState('');
    const [degreeFile, setDegreeFile] = useState('');
    const [jobFile, setJobFile] = useState('');
    const [divorceFile, setDivorceFile] = useState('');
    const [occupation, setOccupation] = useState('');
    const [complextion, setComplextion] = useState('');
    const [marritalstatus, setMarritalstatus] = useState('');
    const [jointfamily, setJointfamily] = useState('');
    const [relocatecountry, setRelocatecountry] = useState('');
    const [relocatestate, setRelocatestate] = useState('');
    const [sahajmarriageearlier, setSahajmarriageearlier] = useState('');
    const [earlierselection, setEarlierselection] = useState('');

    const genderData = [{ label: "Male", value: "Male" }, { label: "Female", value: "Female" }];
    const occupationData = [{ label: "Service", value: "Service" }, { label: "Self Employed", value: "Self Employed" },
    { label: "Business", value: "Business" }, { label: "Farmer", value: "Farmer" }, { label: "Unemployed", value: "Unemployed" }];
    const complextionData = [{ label: "Fair", value: "Fair" }, { label: "Wheatish", value: "Wheatish" }, { label: "Dark", value: "Dark" }];
    const marritalStatusData = [{ label: "Single", value: "Single" }, { label: "Married", value: "Married" }, { label: "Widow", value: "Widow" },
    { label: "Widower", value: "Widower" }, { label: "Divorcee", value: "Divorcee" }, { label: "Separated but not divorced", value: "Separated but not divorced" }];
    const selectionData = [{ label: "Yes", value: "Yes" }, { label: "No", value: "No" }];
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const navigate = useNavigate();
    const getEvents = () => {
        fetch(
            service.GET_EVENTS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.eventId, label: item.event }));
                setEventData(data2);
            });
    };
    const getCountries = () => {
        fetch(
            service.COUNTRY_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.countryId, label: item.country }));
                setCountryData(data2);
                setFatherCountryData(data2);
            });
    };
    const getStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setStateData(data2);
            });
    };

    const getFatherStates = (id) => {
        fetch(
            service.GET_STATE_BY_COUNTRY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CountryId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.stateId, label: item.state }));
                setFatherStateData(data2);
            });
    };
    const getDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setDistrictData(data2);
            });
    };

    const getFatherDistricts = (id) => {
        fetch(
            service.GET_DISTRICT_BY_STATE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    StateId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.districtId, label: item.district }));
                setFatherDistrictData(data2);
            });
    };
    const getCities = (id) => {
        fetch(
            service.GET_CITY_BY_DISTRICT_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    DistrictId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.cityId, label: item.city }));
                setCityData(data2);
            });
    };
    const getFatherCities = (id) => {
        fetch(
            service.GET_CITY_BY_DISTRICT_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    DistrictId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.cityId, label: item.city }));
                setFatherCityData(data2);
            });
    };
    const getDetailsById = () => {
        setLoading(true);
        const errs = {};
        fetch(
            service.GET_MARRIAGE_APPLICATION_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    RegistrationId: JSON.parse(sessionStorage.getItem('userData')).registrationId,
                })
            })
            .then(res => res.json())
            .then(result => {                
                if (result.message.messageType === 'Success') {
                    setState({
                        ...state, firstname: result.registration.firstName, middlename: result.registration.middleName, lastname: result.registration.lastName,
                        center: result.registration.center, emailid: result.registration.emailId, mobileno: result.registration.mobileNo,
                        pan: result.registration.pan, adharcardno: result.registration.adharCardNo, realizationmonthyear: result.registration.realizationMonthYear,
                        flatno: result.registration.flatNo, buildingname: result.registration.buildingName, streetname: result.registration.streetname, locality: result.registration.locality,
                        village: result.registration.village, pincode: result.registration.pinCode, password: result.registration.password, attendingsince: result.registration.attendingSince,
                        citycoordinatorname: result.registration.cityCoordinatorName, citycoordinatorcontact: result.registration.cityCoordinatorContact, citycoordinatoremailId: result.registration.cityCoordinatorEmailId,
                        registrationid: result.registrationId, applicationid: result.applicationId, applicationno: result.applicationNo, fathername: result.fatherName, relation: result.relation,
                        fatheroccupation: result.fatherOccupation, fatherannualincome: result.fatherAnnualIncome,
                        fatheremailid: result.fatherEmailId, fathermobileno: result.fatherMobileNo, fatherflatno: result.fatherFlatNo,
                        fatherbuildingname: result.fatherBuildingName, fatherstreetname: result.fatherStreetName, fatherlocality: result.fatherLocality,
                        fathervillage: result.fatherVillage, fatherpincode: result.fatherPinCode,
                        exam1: result.exam1, degree1: result.degree1, passingyear1: result.passingYear1, university1: result.university1, achievement1: result.achievement1,
                        exam2: result.exam2, degree2: result.degree2, passingyear2: result.passingYear2, university2: result.university2, achievement2: result.achievement2,
                        exam3: result.exam3, degree3: result.degree3, passingyear3: result.passingYear3, university3: result.university3, achievement3: result.achievement3,
                        exam4: result.exam4, degree4: result.degree4, passingyear4: result.passingYear4, university4: result.university4, achievement4: result.achievement4,
                        exam5: result.exam5, degree5: result.degree5, passingyear5: result.passingYear5, university5: result.university5, achievement5: result.achievement5,
                        annualincome: result.annualIncome, designation: result.designation, organizationname: result.organizationName,
                        natureofwork: result.natureOfWork, height: result.height, weight: result.weight,
                        handicap: result.handicap, hobbies: result.hobbies, previoushealthproblem: result.previousHealthProblem, presenthealthproblem: result.presentHealthProblem,
                        son: result.son, daughter: result.daughter,
                        familymembername1: result.familyMemberName1, familymemberrelation1: result.familyMemberRelation1, syjoinedyear1: result.syJoinedYear1, sycenter1: result.syCenter1, sahajmarriage1: result.sahajMarriage1,
                        familymembername2: result.familyMemberName2, familymemberrelation2: result.familyMemberRelation2, syjoinedyear2: result.syJoinedYear2, sycenter2: result.syCenter2, sahajmarriage2: result.sahajMarriage2,
                        familymembername3: result.familyMemberName3, familymemberrelation3: result.familyMemberRelation3, syjoinedyear3: result.syJoinedYear3, sycenter3: result.syCenter3, sahajmarriage3: result.sahajMarriage3,
                        familymembername4: result.familyMemberName4, familymemberrelation4: result.familyMemberRelation4, syjoinedyear4: result.syJoinedYear4, sycenter4: result.syCenter4, sahajmarriage4: result.sahajMarriage4,
                        familymembername5: result.familyMemberName5, familymemberrelation5: result.familyMemberRelation5, syjoinedyear5: result.syJoinedYear5, sycenter5: result.syCenter5, sahajmarriage5: result.sahajMarriage5,
                        relationsstayingtogether: result.relationsStayingTogether, earlieryear: result.earlierYear,
                        reasonofnotmarried: result.reasonOfNotMarried, citycoordinatorfeedback: result.cityCoordinatorFeedback,
                        districtcoordinatorfeedback: result.districtCoordinatorFeedback, statecoordinatorfeedback: result.stateCoordinatorFeedback,
                        panfilename: result.registration.panFileName, adharfilename: result.registration.adharFileName, photofilename: result.photoFileName,
                        degreefilename: result.degreeFileName, jobfilename: result.jobFileName, divorcefilename: result.divorceFileName,
                        sentforapproval: result.sentForApproval
                    });

                    const conData = ({ value: result.registration.countryId, label: result.registration.country });
                    setCountryId(conData);
                    getStates(conData.value);
                    const stData = ({ value: result.registration.stateId, label: result.registration.state });
                    setStateId(stData);
                    getDistricts(stData.value);
                    const disData = ({ value: result.registration.districtId, label: result.registration.district });
                    setDistrictId(disData);
                    getCities(disData.value);
                    const ctData = ({ value: result.registration.cityId, label: result.registration.city });
                    setCityId(ctData);

                    const fconData = ({ value: result.fatherCountryId, label: result.fatherCountry });
                    setFatherCountryId(fconData);
                    getFatherStates(fconData.value);
                    const fstData = ({ value: result.fatherStateId, label: result.fatherState });
                    setFatherStateId(fstData);
                    getFatherDistricts(fstData.value);
                    const fdisData = ({ value: result.fatherDistrictId, label: result.fatherDistrict });
                    setFatherDistrictId(fdisData);
                    getFatherCities(fdisData.value);
                    const fctData = ({ value: result.fatherCityId, label: result.fatherCity });
                    setFatherCityId(fctData);

                    const gen = ({ value: result.registration.gender, label: result.registration.gender });
                    setGender(gen);
                    setBirthDate(new Date(result.registration.birthDate));

                    const eveData = ({ value: result.eventId, label: result.event });
                    setEventId(eveData);
                    const occu = ({ value: result.occupation, label: result.occupation });
                    setOccupation(occu);
                    const comp = ({ value: result.complextion, label: result.complextion });
                    setComplextion(comp);
                    const marr = ({ value: result.marritalStatus, label: result.marritalStatus });
                    setMarritalstatus(marr);
                    const fam = ({ value: result.jointFamily, label: result.jointFamily });
                    setJointfamily(fam);
                    const relCo = ({ value: result.relocateCountry, label: result.relocateCountry });
                    setRelocatecountry(relCo);
                    const relSt = ({ value: result.relocateState, label: result.relocateState });
                    setRelocatestate(relSt);
                    const marrEar = ({ value: result.sahajMarriageEarlier, label: result.sahajMarriageEarlier });
                    setSahajmarriageearlier(marrEar);
                    const marrSel = ({ value: result.earlierSelection, label: result.earlierSelection });
                    setEarlierselection(marrSel);
                }
                else if (result.message.messageType === 'Failed') {
                    setState({
                        ...state, firstname: result.registration.firstName, middlename: result.registration.middleName, lastname: result.registration.lastName,
                        center: result.registration.center, emailid: result.registration.emailId, mobileno: result.registration.mobileNo,
                        pan: result.registration.pan, adharcardno: result.registration.adharCardNo, realizationmonthyear: result.registration.realizationMonthYear,
                        flatno: result.registration.flatNo, buildingname: result.registration.buildingName, streetname: result.registration.streetname, locality: result.registration.locality,
                        village: result.registration.village, pincode: result.registration.pinCode, password: result.registration.password, attendingsince: result.registration.attendingSince,
                        citycoordinatorname: result.registration.cityCoordinatorName, citycoordinatorcontact: result.registration.cityCoordinatorContact, citycoordinatoremailId: result.registration.cityCoordinatorEmailId,
                        registrationid: result.registrationId, applicationno: result.applicationNo, panfilename: result.registration.panFileName, adharfilename: result.registration.adharFileName
                    });

                    const conData = ({ value: result.registration.countryId, label: result.registration.country });
                    setCountryId(conData);
                    getStates(conData.value);
                    const stData = ({ value: result.registration.stateId, label: result.registration.state });
                    setStateId(stData);
                    getDistricts(stData.value);
                    const disData = ({ value: result.registration.districtId, label: result.registration.district });
                    setDistrictId(disData);
                    getCities(disData.value);
                    const ctData = ({ value: result.registration.cityId, label: result.registration.city });
                    setCityId(ctData);
                    const gen = ({ value: result.registration.gender, label: result.registration.gender });
                    setGender(gen);

                    setBirthDate(new Date(result.registration.birthDate));
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }
            });
        setLoading(false);
    };

    useEffect(() => {
        getDetailsById();
        getCountries();        
        getEvents();
        if (state.applicationid === 0) {
            const def = { value: 1, label: 'India' };
            setCountryId(def);
            setFatherCountryId(def);
            getStates(def.value);
            getFatherStates(def.value);

            const evnt = { value: 1, label: 'BIRTHDAY PUJA MAHOSTAV 2023' };
            setEventId(evnt);

            const gen = { label: 'Male', value: 'Male' };
            setGender(gen);
        }
    }, []);

    function getExtension(filename) {
        return filename.split('.').pop()
    }

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!state.exam1) {
            isValid = false;
            errs[".exam1"] = "Please enter education details";
        }
        if (!state.degree1) {
            isValid = false;
            errs[".degree1"] = "Please enter education details";
        }
        if (!state.passingyear1) {
            isValid = false;
            errs[".passingyear1"] = "Please enter education details";
        }
        if (!state.university1) {
            isValid = false;
            errs[".university1"] = "Please enter education details";
        }
        if (!occupation) {
            isValid = false;
            errs[".occupation"] = "Please select occupation";
        }
        if (!state.annualincome) {
            isValid = false;
            errs[".annualincome"] = "Please enter annual income";
        }
        if (!state.designation) {
            isValid = false;
            errs[".designation"] = "Please enter present designation";
        }
        if (!state.organizationname) {
            isValid = false;
            errs[".organizationname"] = "Please enter present organizaion name";
        }
        if (!state.natureofwork) {
            isValid = false;
            errs[".natureofwork"] = "Please enter present nature of work";
        }
        if (!state.height) {
            isValid = false;
            errs[".height"] = "Please enter height";
        }
        if (!state.weight) {
            isValid = false;
            errs[".weight"] = "Please enter weight";
        }
        if (!complextion) {
            isValid = false;
            errs[".complextion"] = "Please select complextion";
        }
        if (!marritalstatus.value) {
            isValid = false;
            errs[".marritalstatus"] = "Please select marrital status";
        }
        if (!jointfamily) {
            isValid = false;
            errs[".jointfamily"] = "Please select";
        }
        if (!relocatecountry) {
            isValid = false;
            errs[".relocatecountry"] = "Please select";
        }
        if (!relocatestate) {
            isValid = false;
            errs[".relocatestate"] = "Please select";
        }
        if (!sahajmarriageearlier) {
            isValid = false;
            errs[".sahajmarriageearlier"] = "Please enter";
        }
        if (sahajmarriageearlier.value === 'Yes' && !state.earlieryear) {
            isValid = false;
            errs[".earlieryear"] = "Please enter earlier years";
        }
        if (sahajmarriageearlier.value === 'Yes' && !earlierselection) {
            isValid = false;
            errs[".earlierselection"] = "Please select";
        }
        if (sahajmarriageearlier.value === 'Yes' && earlierselection.value === 'Yes' && !state.reasonofnotmarried) {
            isValid = false;
            errs[".reasonofnotmarried"] = "Please enter reason";
        }
        if (state.applicationid === 0 && !photoFile) {
            isValid = false;
            errs[".photoFile"] = "Please upload profile photo";
        }
        if (state.applicationid === 0 && !degreeFile) {
            isValid = false;
            errs[".degreeFile"] = "Please upload Degree File";
        }
        if (state.applicationid === 0 && !jobFile) {
            isValid = false;
            errs[".jobFile"] = "Please upload Current Job File";
        }
        if (photoFile && getExtension(photoFile.name).toLowerCase() !== 'jpg'
            && photoFile && getExtension(photoFile.name).toLowerCase() !== 'jpeg') {
            isValid = false;
            errs[".photoFile"] = "Please upload jpg/jpeg file";
        }
        if (degreeFile && getExtension(degreeFile.name).toLowerCase() !== 'pdf') {
            isValid = false;
            errs[".degreeFile"] = "Please upload pdf file";
        }
        if (jobFile && getExtension(jobFile.name).toLowerCase() !== 'pdf') {
            isValid = false;
            errs[".jobFile"] = "Please upload pdf file";
        }
        if (divorceFile && getExtension(divorceFile.name).toLowerCase() !== 'pdf') {
            isValid = false;
            errs[".divorceFile"] = "Please upload pdf file";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const eventChangeHandler = (ev) => {
        setEventId(ev);
    }
    const countryChangeHandler = (ev) => {
        setCountryId(ev);
        setStateId(null);
        setDistrictId(null);
        getStates(ev.value);
    }
    const stateChangeHandler = (ev) => {
        setStateId(ev);
        setDistrictId(null);
        getDistricts(ev.value);
    }
    const districtChangeHandler = (ev) => {
        setDistrictId(ev);
        setCityId(null)
        getCities(ev.value);
    }
    const cityChangeHandler = (ev) => {
        setCityId(ev);
    }
    const fatherCountryChangeHandler = (ev) => {
        setFatherCountryId(ev);
        setFatherStateId(null);
        setFatherDistrictId(null);
        getFatherStates(ev.value);
    }
    const fatherStateChangeHandler = (ev) => {
        setFatherStateId(ev);
        setFatherDistrictId(null);
        getFatherDistricts(ev.value);
    }
    const fatherDistrictChangeHandler = (ev) => {
        setFatherDistrictId(ev);
        setFatherCityId(null)
        getFatherCities(ev.value);
    }
    const fatherCityChangeHandler = (ev) => {
        setFatherCityId(ev);
    }

    const SaveDetails = (status) => {
        const errs = {};
        if (validate()) {
            const formData = new FormData();
            formData.append("RegistrationId", state.registrationid);
            formData.append("ApplicationId", state.applicationid);
            formData.append("ApplicationNo", state.applicationno);
            formData.append("EventId", eventId.value);
            formData.append("FatherName", state.fathername);
            formData.append("Relation", state.relation);
            formData.append("FatherOccupation", state.fatheroccupation);
            formData.append("FatherAnnualIncome", state.fatherannualincome);
            formData.append("FatherFlatNo", state.fatherflatno);
            formData.append("FatherBuildingName", state.fatherbuildingname);
            formData.append("FatherStreetName", state.fatherstreetname);
            formData.append("FatherLocality", state.fatherlocality);
            formData.append("FatherVillage", state.fathervillage);
            formData.append("FatherPinCode", state.fatherpincode);
            formData.append("FatherCityId", fatherCityId ? fatherCityId.value : 0);
            formData.append("FatherDistrictId", fatherDistrictId ? fatherDistrictId.value : 0);
            formData.append("FatherStateId", fatherStateId ? fatherStateId.value : 0);
            formData.append("FatherCountryId", fatherCountryId.value);
            formData.append("FatherMobileNo", state.fathermobileno);
            formData.append("FatherEmailId", state.fatheremailid);
            formData.append("Exam1", state.exam1);
            formData.append("Degree1", state.degree1);
            formData.append("PassingYear1", state.passingyear1);
            formData.append("University1", state.university1);
            formData.append("Achievement1", state.achievement1);
            formData.append("Exam2", state.exam2);
            formData.append("Degree2", state.degree2);
            formData.append("PassingYear2", state.passingyear2);
            formData.append("University2", state.university2);
            formData.append("Achievement2", state.achievement2);
            formData.append("Exam3", state.exam3);
            formData.append("Degree3", state.degree3);
            formData.append("PassingYear3", state.passingyear3);
            formData.append("University3", state.university3);
            formData.append("Achievement3", state.achievement3);
            formData.append("Exam4", state.exam4);
            formData.append("Degree4", state.degree4);
            formData.append("PassingYear4", state.passingyear4);
            formData.append("University4", state.university4);
            formData.append("Achievement4", state.achievement4);
            formData.append("Exam5", state.exam5);
            formData.append("Degree5", state.degree5);
            formData.append("PassingYear5", state.passingyear5);
            formData.append("University5", state.university5);
            formData.append("Achievement5", state.achievement5);
            formData.append("Occupation", occupation.value);
            formData.append("AnnualIncome", state.annualincome);
            formData.append("Designation", state.designation);
            formData.append("OrganizationName", state.organizationname);
            formData.append("NatureOfWork", state.natureofwork);
            formData.append("Height", state.height);
            formData.append("Weight", state.weight);
            formData.append("Complextion", complextion.value);
            formData.append("Handicap", state.handicap);
            formData.append("Hobbies", state.hobbies);
            formData.append("PreviosHealthProblem", state.previoushealthproblem);
            formData.append("PresentHealthProblem", state.presenthealthproblem);
            formData.append("MarritalStatus", marritalstatus.value);
            formData.append("Son", state.son);
            formData.append("Daughter", state.daughter);
            formData.append("FamilyMemberName1", state.familymembername1);
            formData.append("FamilyMemberRelation1", state.familymemberrelation1);
            formData.append("SYJoinedYear1", state.syjoinedyear1);
            formData.append("SYCenter1", state.sycenter1);
            formData.append("SahajMarriage1", state.sahajmarriage1);
            formData.append("FamilyMemberName2", state.familymembername2);
            formData.append("FamilyMemberRelation2", state.familymemberrelation2);
            formData.append("SYJoinedYear2", state.syjoinedyear2);
            formData.append("SYCenter2", state.sycenter2);
            formData.append("SahajMarriage2", state.sahajmarriage2);
            formData.append("FamilyMemberName3", state.familymembername3);
            formData.append("FamilyMemberRelation3", state.familymemberrelation3);
            formData.append("SYJoinedYear3", state.syjoinedyear3);
            formData.append("SYCenter3", state.sycenter3);
            formData.append("SahajMarriage3", state.sahajmarriage3);
            formData.append("FamilyMemberName4", state.familymembername4);
            formData.append("FamilyMemberRelation4", state.familymemberrelation4);
            formData.append("SYJoinedYear4", state.syjoinedyear4);
            formData.append("SYCenter4", state.sycenter4);
            formData.append("SahajMarriage4", state.sahajmarriage4);
            formData.append("FamilyMemberName5", state.familymembername5);
            formData.append("FamilyMemberRelation5", state.familymemberrelation5);
            formData.append("SYJoinedYear5", state.syjoinedyear5);
            formData.append("SYCenter5", state.sycenter5);
            formData.append("SahajMarriage5", state.sahajmarriage5);
            formData.append("JointFamily", jointfamily.value);
            formData.append("RelationsStayingTogether", state.relationsstayingtogether);
            formData.append("SahajMarriageEarlier", sahajmarriageearlier.value);
            formData.append("EarlierYear", state.earlieryear);
            formData.append("EarlierSelection", earlierselection ? earlierselection.value : 'No');
            formData.append("ReasonOfNotMarried", state.reasonofnotmarried);
            formData.append("RelocateCountry", relocatecountry.value);
            formData.append("RelocateState", relocatestate.value);
            formData.append("CityCoordinatorFeedback", false);
            formData.append("DistrictCoordinatorFeedback", false);
            formData.append("StateCoordinatorFeedback", false);
            formData.append("SentForApproval", status);
            formData.append("PhotoFile", photoFile);
            formData.append("DegreeFile", degreeFile);
            formData.append("JobFile", jobFile);
            formData.append("DivorceFile", divorceFile);
            fetch(
                service.ADD_UPDATE_MARRIAGE_APPLICATION,
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);   
                        // getDetailsById();                     
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
    }
    const submitHandler = e => {
        e.preventDefault();
        setLoading(true);
        SaveDetails(false);
        setLoading(false);
    }
    const sendForApprovalHandler = e => {
        e.preventDefault();
        setLoading(true);
         // eslint-disable-next-line
        if (window.confirm("Do you want to send the application for further processing? You won't be able to change it later...")) {
            SaveDetails(true);
        }
        setLoading(false);
    }

    const printHandler = () => {
        navigate(`/marriageformsuccess/${state.registrationid}`);
    }
    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    }
    return (
        <div style={{ width: '100%' }}>
            {/* <DashboardNavbar /> */}
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Marriage Application
                                </MDTypography>
                            </MDBox>
                            <Card style={{ alignItems: 'center' }}>
                                <br />
                                <TableContainer component={Paper} style={{ width: '95%', alignSelf: 'center' }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="event"
                                                        name="event"
                                                        options={eventData}
                                                        value={eventId}
                                                        onChange={eventChangeHandler}
                                                        label="Event"
                                                    />
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Application No" fullWidth value={state.applicationno || ''}
                                                        name="applicationno"
                                                        onChange={changeHandler}
                                                        id="applicationno"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".applicationno"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="First Name" fullWidth value={state.firstname || ''}
                                                        name="firstname"
                                                        onChange={changeHandler}
                                                        id="firstname"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".firstname"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Middle Name" fullWidth value={state.middlename || ''}
                                                        name="middlename"
                                                        onChange={changeHandler}
                                                        id="middlename"
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".middlename"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Last Name" fullWidth value={state.lastname || ''}
                                                        name="lastname"
                                                        onChange={changeHandler}
                                                        id="lastname"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".lastname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Flat No." fullWidth value={state.flatno || ''}
                                                        name="flatno"
                                                        onChange={changeHandler}
                                                        id="flatno"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".flatno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Building Name" fullWidth value={state.buildingname || ''}
                                                        name="buildingname"
                                                        onChange={changeHandler}
                                                        id="buildingname"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".buildingname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Street Name" fullWidth value={state.streetname || ''}
                                                        name="streetname"
                                                        onChange={changeHandler}
                                                        id="streetname"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".streetname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Locality" fullWidth value={state.locality || ''}
                                                        name="locality"
                                                        onChange={changeHandler}
                                                        id="locality"
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".locality"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Village" fullWidth value={state.village || ''}
                                                        name="village"
                                                        onChange={changeHandler}
                                                        id="village"
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".village"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Pin Code" fullWidth value={state.pincode || ''}
                                                        name="pincode"
                                                        onChange={changeHandler}
                                                        id="pincode"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pincode"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="country"
                                                        name="country"
                                                        options={countryData}
                                                        value={countryId}
                                                        onChange={countryChangeHandler}
                                                        label="Country"
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".country"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        options={stateData}
                                                        name="state"
                                                        value={stateId}
                                                        onChange={stateChangeHandler}
                                                        placeholder="Select State"
                                                        isSearchable
                                                        styles={selectStyles}
                                                        isDisabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".state"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={districtData}
                                                        name="district"
                                                        value={districtId}
                                                        onChange={districtChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                        isDisabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".district"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={cityData}
                                                        name="city"
                                                        value={cityId}
                                                        onChange={cityChangeHandler}
                                                        isSearchable
                                                        placeholder="Select City"
                                                        styles={selectStyles}
                                                        isDisabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".city"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Mobile No" fullWidth value={state.mobileno || ''}
                                                        name="mobileno"
                                                        onChange={changeHandler}
                                                        id="mobileno"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mobileno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Email" fullWidth value={state.emailid || ''}
                                                        name="emailid"
                                                        type="email"
                                                        onChange={changeHandler}
                                                        id="emailid"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".emailId"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="gender"
                                                        name="gender"
                                                        options={genderData}
                                                        value={gender}
                                                        onChange={ev => setGender(ev)}
                                                        placeholder="Select Gender"
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".gender"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    Birth Date
                                                    <DatePicker
                                                        id="birthDate"
                                                        selected={birthDate}
                                                        onChange={date => setBirthDate(date)}
                                                        dateFormat="dd/MM/yyyy"
                                                        tabIndex={0}
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".birthDate"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Center" fullWidth value={state.center || ''}
                                                        name="center"
                                                        onChange={changeHandler}
                                                        id="center"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".center"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Attending Since" fullWidth value={state.attendingsince || ''}
                                                        name="attendingsince"
                                                        onChange={changeHandler}
                                                        id="attendingsince"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".attendingsince"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="City Coordinator Name" fullWidth value={state.citycoordinatorname || ''}
                                                        name="citycoordinatorname"
                                                        onChange={changeHandler}
                                                        id="citycoordinatorname"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".citycoordinatorname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="City Coordinator Contact" fullWidth value={state.citycoordinatorcontact || ''}
                                                        name="citycoordinatorcontact"
                                                        onChange={changeHandler}
                                                        id="citycoordinatorcontact"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".citycoordinatorcontact"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="City Coordinator EmailId" fullWidth value={state.citycoordinatoremailId || ''}
                                                        name="citycoordinatoremailId"
                                                        onChange={changeHandler}
                                                        id="citycoordinatoremailId"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".citycoordinatoremailId"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Realization Month-Year" fullWidth value={state.realizationmonthyear || ''}
                                                        name="realizationmonthyear"
                                                        onChange={changeHandler}
                                                        id="realizationmonthyear"
                                                        required
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".realizationmonthyear"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="PAN" fullWidth value={state.pan || ''}
                                                        name="pan"
                                                        onChange={changeHandler}
                                                        id="pan"
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".pan"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Adhar Card No" fullWidth value={state.adharcardno || ''}
                                                        name="adharcardno"
                                                        onChange={changeHandler}
                                                        id="adharcardno"
                                                        disabled
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".adharcardno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    PAN :
                                                    <div style={{ width: 300, fontSize: 14 }}>{state.panfilename}</div>
                                                </TableCell>
                                                <TableCell>
                                                    Adhar Card:
                                                    <div style={{ width: 300, fontSize: 14 }}>{state.adharfilename}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                Education Details (Please mention only the highest qualification)
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell>S.No.</TableCell>
                                                        <TableCell>Examination Passed</TableCell>
                                                        <TableCell>Degree/Certificate</TableCell>
                                                        <TableCell>Year Of Passing</TableCell>
                                                        <TableCell>School/College/University</TableCell>
                                                        <TableCell>Distinguished Performance/Achievements</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>1</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Exam" fullWidth value={state.exam1 || ''}
                                                                name="exam1"
                                                                onChange={changeHandler}
                                                                id="exam1"
                                                                required
                                                            />
                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".exam1"]}</div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Degree/Cerificate" fullWidth value={state.degree1 || ''}
                                                                name="degree1"
                                                                onChange={changeHandler}
                                                                id="degree1"
                                                                required
                                                            />
                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".degree1"]}</div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Year Of Passing" fullWidth value={state.passingyear1 || ''}
                                                                name="passingyear1"
                                                                onChange={changeHandler}
                                                                id="passingyear1"
                                                                required
                                                            />
                                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".passingyear1"]}</div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="School/College/Univ" fullWidth value={state.university1 || ''}
                                                                name="university1"
                                                                onChange={changeHandler}
                                                                id="university1"
                                                                required
                                                            />
                                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".university1"]}</div>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Achievements" fullWidth value={state.achievement1 || ''}
                                                                name="achievement1"
                                                                onChange={changeHandler}
                                                                id="achievement1"                                                                
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>2</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Exam" fullWidth value={state.exam2 || ''}
                                                                name="exam2"
                                                                onChange={changeHandler}
                                                                id="exam2"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Degree/Cerificate" fullWidth value={state.degree2 || ''}
                                                                name="degree2"
                                                                onChange={changeHandler}
                                                                id="degree2"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Year Of Passing" fullWidth value={state.passingyear2 || ''}
                                                                name="passingyear2"
                                                                onChange={changeHandler}
                                                                id="passingyear2"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="School/College/Univ" fullWidth value={state.university2 || ''}
                                                                name="university2"
                                                                onChange={changeHandler}
                                                                id="university2"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Achievements" fullWidth value={state.achievement2 || ''}
                                                                name="achievement2"
                                                                onChange={changeHandler}
                                                                id="achievement2"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>3</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Exam" fullWidth value={state.exam3 || ''}
                                                                name="exam3"
                                                                onChange={changeHandler}
                                                                id="exam3"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Degree/Cerificate" fullWidth value={state.degree3 || ''}
                                                                name="degree3"
                                                                onChange={changeHandler}
                                                                id="degree3"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Year Of Passing" fullWidth value={state.passingyear3 || ''}
                                                                name="passingyear3"
                                                                onChange={changeHandler}
                                                                id="passingyear3"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="School/College/Univ" fullWidth value={state.university3 || ''}
                                                                name="university3"
                                                                onChange={changeHandler}
                                                                id="university3"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Achievements" fullWidth value={state.achievement3 || ''}
                                                                name="achievement3"
                                                                onChange={changeHandler}
                                                                id="achievement3"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>4</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Exam" fullWidth value={state.exam4 || ''}
                                                                name="exam4"
                                                                onChange={changeHandler}
                                                                id="exam4"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Degree/Cerificate" fullWidth value={state.degree4 || ''}
                                                                name="degree4"
                                                                onChange={changeHandler}
                                                                id="degree4"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Year Of Passing" fullWidth value={state.passingyear4 || ''}
                                                                name="passingyear4"
                                                                onChange={changeHandler}
                                                                id="passingyear4"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="School/College/Univ" fullWidth value={state.university4 || ''}
                                                                name="university4"
                                                                onChange={changeHandler}
                                                                id="university4"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Achievements" fullWidth value={state.achievement4 || ''}
                                                                name="achievement4"
                                                                onChange={changeHandler}
                                                                id="achievement4"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>5</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Exam" fullWidth value={state.exam5 || ''}
                                                                name="exam5"
                                                                onChange={changeHandler}
                                                                id="exam5"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Degree/Cerificate" fullWidth value={state.degree5 || ''}
                                                                name="degree5"
                                                                onChange={changeHandler}
                                                                id="degree5"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Year Of Passing" fullWidth value={state.passingyear5 || ''}
                                                                name="passingyear5"
                                                                onChange={changeHandler}
                                                                id="passingyear5"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="School/College/Univ" fullWidth value={state.university5 || ''}
                                                                name="university5"
                                                                onChange={changeHandler}
                                                                id="university5"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Achievements" fullWidth value={state.achievement5 || ''}
                                                                name="achievement5"
                                                                onChange={changeHandler}
                                                                id="achievement5"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                Professional Details
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ width: '35%' }}>
                                                <Dropdown menuClassName='myMenuClassName' id="occupation"
                                                    name="occupation"
                                                    options={occupationData}
                                                    value={occupation}
                                                    onChange={ev => setOccupation(ev)}
                                                    placeholder="Select Occupation"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".occupation"]}</div>
                                            </TableCell>
                                            <TableCell style={{ width: '35%' }}>
                                                <MDInput label="Annual Income (Rs.)" fullWidth value={state.annualincome || ''}
                                                    name="annualincome"
                                                    onChange={changeHandler}
                                                    id="annualincome"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".annualincome"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Present Assignment
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="Designation" fullWidth value={state.designation || ''}
                                                    name="designation"
                                                    onChange={changeHandler}
                                                    id="designation"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".designation"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Organization Name" fullWidth value={state.organizationname || ''}
                                                    name="organizationname"
                                                    onChange={changeHandler}
                                                    id="organizationname"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".organizationname"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Nature Of Work" fullWidth value={state.natureofwork || ''}
                                                    name="natureofwork"
                                                    onChange={changeHandler}
                                                    id="natureofwork"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".natureofwork"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                Physical
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ width: '35%' }}>
                                                <MDInput label="Height (in Ft and inches)" fullWidth value={state.height || ''}
                                                    name="height"
                                                    onChange={changeHandler}
                                                    id="height"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".height"]}</div>
                                            </TableCell>
                                            <TableCell style={{ width: '35%' }}>
                                                <MDInput label="Weight (in Kgs)" fullWidth value={state.weight || ''}
                                                    name="weight"
                                                    onChange={changeHandler}
                                                    id="weight"
                                                    required
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".weight"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <Dropdown menuClassName='myMenuClassName' id="complextion"
                                                    name="complextion"
                                                    options={complextionData}
                                                    value={complextion}
                                                    onChange={ev => setComplextion(ev)}
                                                    placeholder="Select Complextion"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".complextion"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Handicap, if any ( give details, even like eye sight, hearing aid etc)
                                                <MDInput label="Handicap" fullWidth value={state.handicap || ''}
                                                    name="handicap"
                                                    onChange={changeHandler}
                                                    id="handicap"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".handicap"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Previous Health Problems" fullWidth value={state.previoushealthproblem || ''}
                                                    name="previoushealthproblem"
                                                    onChange={changeHandler}
                                                    id="previoushealthproblem"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".previoushealthproblem"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Present Health Problems" fullWidth value={state.presenthealthproblem || ''}
                                                    name="presenthealthproblem"
                                                    onChange={changeHandler}
                                                    id="presenthealthproblem"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".presenthealthproblem"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Dropdown menuClassName='myMenuClassName' id="marritalstatus"
                                                    name="marritalstatus"
                                                    options={marritalStatusData}
                                                    value={marritalstatus}
                                                    onChange={ev => setMarritalstatus(ev)}
                                                    placeholder="Select Marrital Status"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".marritalstatus"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Son(s)" fullWidth value={state.son || ''}
                                                    name="son"
                                                    onChange={changeHandler}
                                                    id="son"
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <MDInput label="Daughter(s)" fullWidth value={state.daughter || ''}
                                                    name="daughter"
                                                    onChange={changeHandler}
                                                    id="daughter"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <MDInput label="hobbies" fullWidth value={state.hobbies || ''}
                                                    name="hobbies"
                                                    onChange={changeHandler}
                                                    id="hobbies"
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    Father’s /Guardian’s Details:
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Name" fullWidth value={state.fathername || ''}
                                                        name="fathername"
                                                        onChange={changeHandler}
                                                        id="fathername"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fathername"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Relation" fullWidth value={state.relation || ''}
                                                        name="relation"
                                                        onChange={changeHandler}
                                                        id="relation"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".relation"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="House/Plot/Flat No." fullWidth value={state.fatherflatno || ''}
                                                        name="fatherflatno"
                                                        onChange={changeHandler}
                                                        id="fatherflatno"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fatherflatno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Building Name" fullWidth value={state.fatherbuildingname || ''}
                                                        name="fatherbuildingname"
                                                        onChange={changeHandler}
                                                        id="fatherbuildingname"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fatherbuildingname"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Street/Road No./Name" fullWidth value={state.fatherstreetname || ''}
                                                        name="fatherstreetname"
                                                        onChange={changeHandler}
                                                        id="fatherstreetname"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".streetname"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Locality/Mohalla" fullWidth value={state.fatherlocality || ''}
                                                        name="fatherlocality"
                                                        onChange={changeHandler}
                                                        id="fatherlocality"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fatherlocality"]}</div>
                                                </TableCell>
                                                <TableCell style={{ width: '35%' }}>
                                                    <MDInput label="Village" fullWidth value={state.fathervillage || ''}
                                                        name="fathervillage"
                                                        onChange={changeHandler}
                                                        id="fathervillage"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fathervillage"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Pin Code" fullWidth value={state.fatherpincode || ''}
                                                        name="fatherpincode"
                                                        onChange={changeHandler}
                                                        id="fatherpincode"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fatherpincode"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Dropdown menuClassName='myMenuClassName' id="fathercountry"
                                                        name="fathercountry"
                                                        options={fatherCountryData}
                                                        value={fatherCountryId}
                                                        onChange={fatherCountryChangeHandler}
                                                        label="Country"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fathercountry"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        options={fatherStateData}
                                                        name="fatherstate"
                                                        value={fatherStateId}
                                                        onChange={fatherStateChangeHandler}
                                                        placeholder="Select State"
                                                        isSearchable
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fatherstate"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={fatherDistrictData}
                                                        name="fatherdistrict"
                                                        value={fatherDistrictId}
                                                        onChange={fatherDistrictChangeHandler}
                                                        isSearchable
                                                        placeholder="Select District"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fatherdistrict"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Select className="basic-single"
                                                        options={fatherCityData}
                                                        name="fathercity"
                                                        value={fatherCityId}
                                                        onChange={fatherCityChangeHandler}
                                                        isSearchable
                                                        placeholder="Select City"
                                                        styles={selectStyles}
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fathercity"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Mobile No" fullWidth value={state.fathermobileno || ''}
                                                        name="fathermobileno"
                                                        onChange={changeHandler}
                                                        id="fathermobileno"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fathermobileno"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Email" fullWidth value={state.fatheremailid || ''}
                                                        name="fatheremailid"
                                                        type="fatheremailid"
                                                        onChange={changeHandler}
                                                        id="fatheremailid"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fatheremailid"]}</div>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <MDInput label="Occupation" fullWidth value={state.fatheroccupation || ''}
                                                        name="fatheroccupation"
                                                        onChange={changeHandler}
                                                        id="fatheroccupation"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fatheroccupation"]}</div>
                                                </TableCell>
                                                <TableCell>
                                                    <MDInput label="Annual Income (Rs.)" fullWidth value={state.fatherannualincome || ''}
                                                        name="fatherannualincome"
                                                        onChange={changeHandler}
                                                        id="fatherannualincome"
                                                    />
                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".fatherannualincome"]}</div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <Table>
                                        <TableRow>
                                            <TableCell>
                                                Family Details
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell>S.No.</TableCell>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Relation</TableCell>
                                                        <TableCell>SY Joined(Year, Center)</TableCell>
                                                        <TableCell>Married In Sahajayoga (Yes/No)</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>1</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Name" fullWidth value={state.familymembername1 || ''}
                                                                name="familymembername1"
                                                                onChange={changeHandler}
                                                                id="familymembername1"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Relation" fullWidth value={state.familymemberrelation1 || ''}
                                                                name="familymemberrelation1"
                                                                onChange={changeHandler}
                                                                id="familymemberrelation1"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="SY Joined(Year, Center)" fullWidth value={state.syjoinedyear1 || ''}
                                                                name="syjoinedyear1"
                                                                onChange={changeHandler}
                                                                id="syjoinedyear1"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Married In Sahajayoga" fullWidth value={state.sahajmarriage1 || ''}
                                                                name="sahajmarriage1"
                                                                onChange={changeHandler}
                                                                id="sahajmarriage1"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>2</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Name" fullWidth value={state.familymembername2 || ''}
                                                                name="familymembername2"
                                                                onChange={changeHandler}
                                                                id="familymembername2"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Relation" fullWidth value={state.familymemberrelation2 || ''}
                                                                name="familymemberrelation2"
                                                                onChange={changeHandler}
                                                                id="familymemberrelation2"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="SY Joined(Year, Center)" fullWidth value={state.syjoinedyear2 || ''}
                                                                name="syjoinedyear2"
                                                                onChange={changeHandler}
                                                                id="syjoinedyear2"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Married In Sahajayoga" fullWidth value={state.sahajmarriage2 || ''}
                                                                name="sahajmarriage2"
                                                                onChange={changeHandler}
                                                                id="sahajmarriage2"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>3</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Name" fullWidth value={state.familymembername3 || ''}
                                                                name="familymembername3"
                                                                onChange={changeHandler}
                                                                id="familymembername3"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Relation" fullWidth value={state.familymemberrelation3 || ''}
                                                                name="familymemberrelation3"
                                                                onChange={changeHandler}
                                                                id="familymemberrelation3"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="SY Joined(Year, Center)" fullWidth value={state.syjoinedyear3 || ''}
                                                                name="syjoinedyear3"
                                                                onChange={changeHandler}
                                                                id="syjoinedyear3"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Married In Sahajayoga" fullWidth value={state.sahajmarriage3 || ''}
                                                                name="sahajmarriage3"
                                                                onChange={changeHandler}
                                                                id="sahajmarriage3"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>4</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Name" fullWidth value={state.familymembername4 || ''}
                                                                name="familymembername4"
                                                                onChange={changeHandler}
                                                                id="familymembername4"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Relation" fullWidth value={state.familymemberrelation4 || ''}
                                                                name="familymemberrelation4"
                                                                onChange={changeHandler}
                                                                id="familymemberrelation4"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="SY Joined(Year, Center)" fullWidth value={state.syjoinedyear4 || ''}
                                                                name="syjoinedyear4"
                                                                onChange={changeHandler}
                                                                id="syjoinedyear4"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Married In Sahajayoga" fullWidth value={state.sahajmarriage4 || ''}
                                                                name="sahajmarriage4"
                                                                onChange={changeHandler}
                                                                id="sahajmarriage4"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell>5</TableCell>
                                                        <TableCell>
                                                            <MDInput label="Name" fullWidth value={state.familymembername5 || ''}
                                                                name="familymembername5"
                                                                onChange={changeHandler}
                                                                id="familymembername5"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Relation" fullWidth value={state.familymemberrelation5 || ''}
                                                                name="familymemberrelation5"
                                                                onChange={changeHandler}
                                                                id="familymemberrelation5"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="SY Joined(Year, Center)" fullWidth value={state.syjoinedyear5 || ''}
                                                                name="syjoinedyear5"
                                                                onChange={changeHandler}
                                                                id="syjoinedyear5"
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDInput label="Married In Sahajayoga" fullWidth value={state.sahajmarriage5 || ''}
                                                                name="sahajmarriage5"
                                                                onChange={changeHandler}
                                                                id="sahajmarriage5"
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell style={{ width: '35%' }}>
                                                Staying With Joined Family:
                                                <Dropdown menuClassName='myMenuClassName' id="jointfamily"
                                                    name="jointfamily"
                                                    options={selectionData}
                                                    value={jointfamily}
                                                    onChange={ev => setJointfamily(ev)}
                                                    placeholder="Select"
                                                />
                                                 <div style={{ fontSize: '12px', color: 'red' }}>{errors[".jointfamily"]}</div>
                                            </TableCell>
                                            <TableCell style={{ width: '35%' }}>
                                                (if yes, indicate the relations staying together)
                                                <MDInput label="Relations Staying Together" fullWidth value={state.relationsstayingtogether || ''}
                                                    name="relationsstayingtogether"
                                                    onChange={changeHandler}
                                                    id="relationsstayingtogether"
                                                />
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Are you willing to change Country
                                                <Dropdown menuClassName='myMenuClassName' id="relocatecountry"
                                                    name="relocatecountry"
                                                    options={selectionData}
                                                    value={relocatecountry}
                                                    onChange={ev => setRelocatecountry(ev)}
                                                    placeholder="Select"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".relocatecountry"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                Are you willing to change State
                                                <Dropdown menuClassName='myMenuClassName' id="relocatestate"
                                                    name="relocatestate"
                                                    options={selectionData}
                                                    value={relocatestate}
                                                    onChange={ev => setRelocatestate(ev)}
                                                    placeholder="Select"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".relocatestate"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Did you apply to Sahaja Marriage earlier
                                                <Dropdown menuClassName='myMenuClassName' id="sahajmarriageearlier"
                                                    name="sahajmarriageearlier"
                                                    options={selectionData}
                                                    value={sahajmarriageearlier}
                                                    onChange={ev => setSahajmarriageearlier(ev)}
                                                    placeholder="Select"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".sahajmarriageearlier"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                (if yes, which year)
                                                <MDInput label="Year" fullWidth value={state.earlieryear || ''}
                                                    name="earlieryear"
                                                    onChange={changeHandler}
                                                    id="earlieryear"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".earlieryear"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                Selected/Not Selected
                                                <Dropdown menuClassName='myMenuClassName' id="earlierselection"
                                                    name="earlierselection"
                                                    options={selectionData}
                                                    value={earlierselection}
                                                    onChange={ev => setEarlierselection(ev)}
                                                    placeholder="Select"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".earlierselection"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                If yes, why marriage did not take place
                                                <MDInput label="Reason" fullWidth value={state.reasonofnotmarried || ''}
                                                    name="reasonofnotmarried"
                                                    onChange={changeHandler}
                                                    id="reasonofnotmarried"
                                                />
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".reasonofnotmarried"]}</div>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Photo Upload
                                                <br />
                                                <TextField
                                                    id="photoFile"
                                                    type="file"
                                                    onChange={ev => setPhotoFile(ev.target.files[0])}
                                                    style={{ width: 300 }}
                                                    required
                                                />
                                                <div style={{ width: 300, fontSize: 14 }}>{state.photofilename}</div>
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".photoFile"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                Degree Upload
                                                <br />
                                                <TextField
                                                    id="degreeFile"
                                                    type="file"
                                                    onChange={ev => setDegreeFile(ev.target.files[0])}
                                                    style={{ width: 300 }}
                                                    required
                                                />
                                                <div style={{ width: 300, fontSize: 14 }}>{state.degreefilename}</div>
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".degreeFile"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                Job File Upload
                                                <br />
                                                <TextField
                                                    id="jobFile"
                                                    type="file"
                                                    onChange={ev => setJobFile(ev.target.files[0])}
                                                    style={{ width: 300 }}
                                                    required
                                                />
                                                <div style={{ width: 300, fontSize: 14 }}>{state.jobfilename}</div>
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".jobFile"]}</div>
                                            </TableCell>
                                            <TableCell>
                                                Divorce File Upload (if applicable)
                                                <br />
                                                <TextField
                                                    id="divorceFile"
                                                    type="file"
                                                    onChange={ev => setDivorceFile(ev.target.files[0])}
                                                    style={{ width: 300 }}
                                                    required
                                                />
                                                <div style={{ width: 300, fontSize: 14 }}>{state.divorcefilename}</div>
                                                <div style={{ fontSize: '12px', color: 'red' }}>{errors[".divorceFile"]}</div>
                                            </TableCell>
                                        </TableRow>
                                    </Table>
                                </TableContainer>
                                <Table style={{ width: '50%' }}>
                                    <TableRow>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={submitHandler} disabled={state.sentforapproval}>
                                                        SAVE
                                                    </MDButton>
                                                }
                                                {/* {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null} */}
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={sendForApprovalHandler} disabled={state.sentforapproval}>
                                                        SEND FOR APPROVAL
                                                    </MDButton>
                                                }                                                
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={printHandler} disabled={!state.sentforapproval}>
                                                        PRINT
                                                    </MDButton>
                                                }                                                
                                            </MDBox>
                                        </TableCell>
                                        <TableCell>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                {loading ? <Oval
                                                    heigth="20"
                                                    width="20"
                                                    color='grey'
                                                    ariaLabel='loading'
                                                /> :
                                                    <MDButton color="info" onClick={backHandler}>
                                                        LOG OUT
                                                    </MDButton>
                                                }                                                
                                            </MDBox>
                                        </TableCell>
                                    </TableRow>
                                </Table>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

            <Footer />
        </div>
    );
}

export default MarriageForm;